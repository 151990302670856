<template>
  <TransparentModal>
    <template v-slot:button="{openModal}">
      <button class="btn d-flex flex-column justify-content-center align-items-center" @click="openModal" style="text-decoration:underline; color: white">
<!--        <img src="/public-assets/icon-edicion" alt= "¡Los productos de EDICIÓN ESPECIAL te dan más oportunidades DE GANAR!*"/>-->
        Ver productos de edición especial
      </button>
    </template>
    <div class="d-flex flex-column justify-content-center align-items-center">
      <h2 class="text-white my-4" style="font-weight: 900;width: calc(100% - 60px);">
        PRODUCTOS EDICIÓN ESPECIAL
      </h2>
<!--      <img class="img-fluid" src="/public-assets/productos-xl" alt= "" style="width: 500px;">-->
    </div>
  </TransparentModal>
</template>

<script>

import TransparentModal from "@components/misc/TransparentModal.vue";

export default {
  name: "SpecialEditionModal",
  components: { TransparentModal }
}
</script>